.container {
    display: flex;
    flex-direction: row;
}

.iconContainer {
    margin-right: 12px;
}

.text {
    font-size: 15px !important;
    color: var(--vkui--color_text_secondary) !important;
    text-align: left;
}
