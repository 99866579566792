.container {
    display: flex;
    height: 100%;
}

.innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 32px 83.5px 64px 67.5px;
}

.title {
    text-align: center;
    margin-bottom: 8px !important;
}

.text {
    font-size: 16px !important;
    color:var(--vkui--color_text_secondary) !important;
    text-align: center;
}

.spinner {
    width: 40px !important;
    height: 40px !important;
}
