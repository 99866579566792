.panel {
    display: flex;
}

.container {
    padding: 16px 66px 60px 66px !important;
    margin-bottom: -1px;
}

.informativeContent {
    display: flex;
}

.optionContainer {
    width: 100%;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-weight: 700 !important;
    text-align: center;
}

.text {
    font-size: 16px !important;
    color: var(--vkui--color_text_secondary) !important;
    line-height: 20px !important;
    text-align: center;
}

.separator {
    width: 2px;
    background-color: var(--vkui--color_text_secondary);
}

.upperBox {
    min-height: 140px;
}

.iconedTextContainer {
    margin: 0 16px;
}

.iconedText {
    font-size: 12px !important;
}

.icon {
    color: var(--vkui--color_icon_secondary);
}

