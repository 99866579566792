.panel {
    display: flex;
}

.container {
    padding: 16px 66px 60px 66px !important;
    margin-bottom: -1px;
}

.informativeContent {
    display: flex;
}

.linkContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formContainer {
    width: 100%;
}

.toFriendButtonOuterContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.toFriendButtonInnerContainer {
    height: 36px;
    width: 100%;
    margin-top: 34px;
    display: flex;
    justify-content: center;
}

.invitationText {
    font-size: 14px !important;
    color: var(--vkui--color_text_secondary);;
    line-height: 20px !important;
    text-align: center;
}

.bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.iconedTextContainer {
    width: 360px;
}

.iconedText {
    line-height: 20px !important;
}

.icon {
    color: var(--vkui--color_icon_secondary);
}

