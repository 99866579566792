.panel {
    display: flex;
}

.container {
    padding: 16px 66px 60px 66px !important;
    margin-bottom: -1px;
}

.informativeContent {
    display: flex;
    flex-direction: column;
    width: 590px;
    margin: auto;
}

.title {
    font-weight: 700 !important;
    text-align: center;
}

.cell {
    margin: auto;
}

.invitationText {
    font-size: 16px !important;
    color: var(--vkui--color_text_secondary);
    line-height: 20px !important;
    text-align: center;
}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.iconedTextContainer {
    width: 360px;
}

.iconedText {
    line-height: 20px !important;
}

.icon {
    color: var(--vkui--color_icon_secondary);
}
