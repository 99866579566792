.blurred {
    filter: blur(8px);
}

.image {
    border-radius: 50%;
    width: 100%;
}

.textContainer {
    margin: 4px 0 10px 0;
}

.title {
    font-size: 16px !important;
    color: var(--vkui--color_text_primary);
    text-align: center;
    margin-bottom: 2px !important;
}

.caption {
    color: var(--vkui--color_text_secondary) !important;
    font-size: 13px !important;
    text-align: center;
}
