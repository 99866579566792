.panel {
    height: 100%;
    display: flex;
}

.container {
    padding: 16px 66px !important;
    height: 100%;
    border-radius: 0 !important;
    margin-bottom: -1px;
}

.title {
    font-weight: 700 !important;
}

.text {
    font-size: 15px !important;
    line-height: 20px !important;
    color: var(--vkui--color_text_subhead);
}

.paragraph {
    margin: 7px 0;
}
