.container {
    padding: 0 !important;
}

.upperTextContainer {
    margin: 0 32px;
}

.subTitle {
    font-size: 16px !important;
    color:var(--vkui--color_text_secondary) !important;
    text-align: center;
    margin-bottom: 8px !important;
}

.title {
    text-align: center;
}

.content {
    margin: 0 68px;
}

.strongFeatures {
    line-height: 20px !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.weakFeatures {
    line-height: 20px !important;
}

.famousPeopleTitle {
    font-weight: 700 !important;
}

.famousPeopleContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 20px;
}

.famousPeopleCellContainer {
    max-width: 147px;
    width:calc(50% - 12px);
    min-width: 100px;
    margin: 0 6px;
}

.agreementContainer {
    margin: 0 16px;
}

.agreementCard {
    padding: 6px 10px;
    margin: 0 0;
}

.banner {
    padding: 12px !important;
}

.bannerBackground {
    background-color: #00121E;
    background-image: url(https://sun9-67.userapi.com/impg/1zU56wmpR8t6uD8X0cRbJslOhqezfK5_Db-Xtw/WxtlXibt9HY.jpg?size=200x200&quality=96&sign=436e1a9a901ebf4ed0c868418edf5e71&type=album);
    background-position: right bottom;
    background-size: 110px;
    background-repeat: no-repeat;
}
