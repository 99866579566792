/* TODO: Выглядит, конечно, мда */
.containerDesktop {
    padding-top: 12px !important;
}

.containerMobile {
    padding-top: 0 !important;
}

.title {
    font-size: 16px !important;
    margin: 8px 12px 7px !important;
}

.text {
    font-size: 26px !important;
    margin: 18px 0 !important;
    text-align: center;
}

.spinner {
    margin-top: 16px;
}
